import React from 'react';
import PropTypes from 'prop-types';

import { useAnimationProps } from 'hooks';
import { HalfRectangle } from 'ui/atoms/HalfRectangle/HalfRectangle';
import { AnimationMap } from 'features/futsal/constants';
import { FieldIcon } from './common/FieldIcon';
import { FieldText } from './common/FieldText';

export function BallPossessionAnimation(props) {
    const { team } = props;
    const { statistics, rectRef, nodeRef, translate } = useAnimationProps(props);

    const { ballPossessionData } = statistics;

    function totalPossessionTime(teamNumber, stats) {
        return stats[teamNumber].reduce((acc, curr) => acc + curr.possessionTime, 0);
    }

    const stats = {
        home: 50,
        away: 50,
    };

    const homePossession = totalPossessionTime(1, ballPossessionData);
    const awayPossession = totalPossessionTime(2, ballPossessionData);
    const totalPossession = homePossession + awayPossession;

    stats.home = totalPossession ? Math.round((homePossession / totalPossession) * 100) : 50;
    stats.away = totalPossession ? 100 - stats.home : 50;

    return (
        <div ref={nodeRef}>
            <HalfRectangle team={team} ref={rectRef} halfCircle />
            <FieldText
                title={translate('action.ballPossession.animation')}
                subText={translate('statistics.possession')}
                team={team}
                homeStat={stats.home}
                awayStat={stats.away}
                homeTeam={translate('team1')}
                awayTeam={translate('team2')}
                isPercent
            />
            <FieldIcon animationName="attack" team={team} />
        </div>
    );
}

BallPossessionAnimation.propTypes = {
    team: PropTypes.number.isRequired,
};

BallPossessionAnimation.ANIMATION_NAME = AnimationMap.BallPossession;
