import React from 'react';
import PropTypes from 'prop-types';

import { useAnimationProps } from 'hooks';
import { HalfRectangle } from 'ui/atoms/HalfRectangle/HalfRectangle';
import { AnimationMap } from 'features/futsal/constants';
import { CornerCircles } from './common/CornerCircles';
import { FieldIcon } from './common/FieldIcon';
import { FieldText } from './common/FieldText';

export function CornerKickAnimation(props) {
    const { team } = props;
    const { statistics, rectRef, nodeRef, translate, store } = useAnimationProps(props);

    const { homeTeam: HOME, awayTeam: AWAY } = store;
    const { cornerKicks } = statistics;
    const homeLen = cornerKicks[HOME].length;
    const awayLen = cornerKicks[AWAY].length;

    function isBottomCorner() {
        /**
         * the type: 1252, doesnt animate, but gets a corner number
         * front based on the following logic adds a new property {cornerNumber} to the
         * incoming data
         * 1 === t.corner || 4 === t.corner ? 2 : 1,
         * So, every 1st and 4th corner is shown at the bottom
         */
        const { cornerNumber } = props;
        return !!cornerNumber && cornerNumber === 2;
    }

    return (
        <div ref={nodeRef}>
            <HalfRectangle team={team} ref={rectRef} halfCircle />
            <FieldText
                title={translate('action.cornerKick.animation')}
                subText={translate('statistics.cornerKicks')}
                homeStat={homeLen}
                awayStat={awayLen}
                team={team}
                homeTeam={translate('team1')}
                awayTeam={translate('team2')}
            />
            <FieldIcon animationName="cornerKick" team={team} />
            <CornerCircles circles={10} team={team} isBottomCorner={isBottomCorner()} />
        </div>
    );
}

CornerKickAnimation.ANIMATION_NAME = AnimationMap.CornerKick;

CornerKickAnimation.propTypes = {
    team: PropTypes.number.isRequired,
    cornerNumber: PropTypes.number,
};

CornerKickAnimation.defaultProps = {
    cornerNumber: null,
};

export function CornerKickWithPositionAnimation(props) {
    return <CornerKickAnimation {...props} />;
}

CornerKickWithPositionAnimation.ANIMATION_NAME = AnimationMap.CornerKickWithPosition;
