/* eslint-disable max-classes-per-file */
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import gsap, { Linear } from 'gsap';
import debounce from 'lodash/debounce';

import { useNodeTimeline } from 'hooks';
import { HalfRectangle } from 'ui/atoms/HalfRectangle/HalfRectangle';
import { FreezedEmitter } from 'features/common/services/emitter.service';
import { AnimationMap } from 'features/football/constants';
import { useKickFromGoalStatistic } from 'features/football/hooks';
import { FieldIcon } from './common/FieldIcon';
import { FieldText } from './common/FieldText';
import { KickFromGoalCircles } from './common/KickFromGoalCircles';

export function KickFromGoalAnimation(props) {
    const { team } = props;
    const [translate] = useTranslation();
    const rectRef = useRef(null);
    const fieldTextRef = useRef(null);
    const circlesRef = useRef(null);

    const { awayStat, homeStat, label: subtext } = useKickFromGoalStatistic();

    function onAnimationComplete() {
        FreezedEmitter.emit('animationCompleted');
    }

    const debouncedAnimationComplete = debounce(onAnimationComplete, 100);

    const rectTimeLine = useRef(
        gsap.timeline({
            repeat: 0,
            yoyo: false,
        }),
    );
    const repeatTL = useRef(
        gsap.timeline({
            repeat: -1,
            repeatDelay: 0.5,
            yoyo: false,
            onRepeat: debouncedAnimationComplete,
        }),
    );

    const nodeRef = useNodeTimeline();

    useEffect(() => {
        // hack to call onRepeat
        repeatTL.current.fromTo(nodeRef.current, { opacity: 1 }, { opacity: 1, duration: 0.8 });
        if (rectRef && fieldTextRef) {
            rectTimeLine.current
                .to(rectRef.current, {
                    opacity: 0.8,
                    duration: 0.3,
                    ease: Linear.easeNone,
                })
                .to(fieldTextRef.current, { duration: 0.2 })
                .fromTo(circlesRef.current, { opacity: 0, scale: 0 }, { opacity: 1, scale: 1, duration: 0.2 })
                .play();
        }
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            repeatTL.current.kill();
        };
    }, [nodeRef]);

    return (
        <div ref={nodeRef}>
            <KickFromGoalCircles team={team} ref={circlesRef} circles={6} />
            <HalfRectangle team={team} ref={rectRef} halfCircle />
            <FieldText
                ref={fieldTextRef}
                title={translate('action.kickFromGoal.animation')}
                subText={subtext}
                homeStat={homeStat}
                awayStat={awayStat}
                team={team}
                homeTeam={translate('team1')}
                awayTeam={translate('team2')}
            />
            <FieldIcon animationName="kickFromGoal" team={team} />
        </div>
    );
}

KickFromGoalAnimation.ANIMATION_NAME = AnimationMap.KickFromGoal;

KickFromGoalAnimation.propTypes = {
    team: PropTypes.number.isRequired,
};
