import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { useRectTimeline, useTargetName } from 'hooks';
import { FullRectangle } from 'ui/atoms/FullRectangle/FullRectangle';
import { AnimationMap } from 'features/football/constants';

export function PlayersOnFieldAnimation() {
    const [translate] = useTranslation();
    const textRef = useRectTimeline();
    const { conditions } = useTargetName();

    return (
        <>
            <FullRectangle />
            <div className={cn(conditions, 'abs-center text-white')}>
                <p className="font-medium animation__field--text-action" ref={textRef}>
                    {translate('action.playersOnField.animation')}
                </p>
            </div>
        </>
    );
}

PlayersOnFieldAnimation.ANIMATION_NAME = AnimationMap.PlayersOnField;
