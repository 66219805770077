import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { HalfRectangle } from 'ui/atoms/HalfRectangle/HalfRectangle';

export function FieldBallPoint(props) {
    const { team, isLong } = props;
    const isHome = team === 1;

    return (
        <HalfRectangle className="field-ball-point" team={team} isLong={isLong}>
            <div className="field_arrow_icon">
                <div className="field_arrow_dot">
                    <span
                        className={cn(
                            'field_arrow_inner_dot',
                            isHome ? 'field_arrow_inner_dot--home' : 'field_arrow_inner_dot--away',
                        )}
                    />
                </div>
            </div>
        </HalfRectangle>
    );
}

FieldBallPoint.propTypes = {
    team: PropTypes.number.isRequired,
    isLong: PropTypes.bool,
};

FieldBallPoint.defaultProps = {
    isLong: false,
};
