import React from 'react';
import { Lottie, useLottie } from 'react-lottie-hook';
import PropTypes from 'prop-types';

import { useAnimationProps, useCallbackTL } from 'hooks';
import { FullRectangle } from 'ui/atoms/FullRectangle/FullRectangle';
import { AnimationMap } from 'features/futsal/constants';
import whistle from './data/whistle.json';

export function HalfTimeEndAnimation(props) {
    const { translate, store, nodeRef } = useAnimationProps();

    const { hasPeriodStarted } = store.translationData.statistics;
    useCallbackTL(nodeRef);

    const [lottieRef] = useLottie({
        renderer: 'svg',
        loop: true,
        autoplay: true,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
            progressiveLoad: false,
        },
        animationData: whistle,
    });

    function getText() {
        const { period } = props;

        if (!period) {
            return translate('action.matchEnded.animation');
        }

        if (period < 2) {
            return translate('action.break.animation');
        }

        if (period > 2 && hasPeriodStarted[period]) {
            return translate('action.endOT.animation', { period: period - 2 });
        }
        return translate('action.matchEnd.animation');
    }

    return (
        <div ref={nodeRef}>
            <FullRectangle />
            <div className="matchEnd">
                <div style={{ height: '50px', width: '60px' }}>
                    <Lottie lottieRef={lottieRef} width="100%" height="100%" />
                </div>
                <p>{getText()}</p>
            </div>
        </div>
    );
}

HalfTimeEndAnimation.propTypes = {
    period: PropTypes.number.isRequired,
};

HalfTimeEndAnimation.ANIMATION_NAME = AnimationMap.HalfTimeEnd;
