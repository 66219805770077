import React from 'react';
import cn from 'classnames';

import { useTeamNumbers } from 'hooks';

function WithRefHalfRectangle(
    { team, isLong, isYellow, isRed, className, halfCircle, isAnimated = true, children },
    ref,
) {
    const [homeTeamNumber] = useTeamNumbers();
    const isHome = homeTeamNumber ? team === homeTeamNumber : team === 1;

    return (
        <div
            className={cn(
                'half-rectangle',
                isHome ? 'half-rectangle__left' : 'half-rectangle__right',
                isLong && 'half-rectangle--long',
                isYellow && 'half-rectangle--yellow',
                isRed && 'half-rectangle--red',
                !isAnimated && 'half-rectangle--opacity',
                className,
            )}
            ref={ref}
        >
            {halfCircle && <div className="half-rectangle__circle" />}
            {children}
        </div>
    );
}

export const HalfRectangle = React.forwardRef(WithRefHalfRectangle);
