import React from 'react';
import PropTypes from 'prop-types';

import { HalfRectangle } from 'ui/atoms/HalfRectangle/HalfRectangle';

export function WithRefFieldAttackArrow(props, ref) {
    const { team, isLong } = props;

    return (
        <HalfRectangle team={team} isLong={isLong} ref={ref} isAnimated={false}>
            <div className="field_arrow_icon">
                <svg
                    preserveAspectRatio="none"
                    width="100%"
                    height="100%"
                    viewBox="0 0 100 365"
                    fill="rgb(62, 90, 2)"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M0 364.5V0L100 183L0 364.5Z" />
                </svg>
            </div>
        </HalfRectangle>
    );
}

export const FieldAttackArrow = React.forwardRef(WithRefFieldAttackArrow);

WithRefFieldAttackArrow.propTypes = {
    team: PropTypes.number.isRequired,
    isLong: PropTypes.bool,
};

WithRefFieldAttackArrow.defaultProps = {
    isLong: false,
};
